import React from "react";
import icon from "../assets/logo.svg";
import { Link } from "react-router-dom";
import Globe from "../assets/globe.png";
import flag2 from "../assets/uaeflag.webp";
import flagSaudi from "../assets/saudi-flag.webp"; // Add Saudi flag asset

import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation("header");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    console.log(i18n.language);
  };

  return (
    <header className="container mx-auto lg:p-5 p-4">
      {/* Mobile Header */}
      <div className="flex flex-col md:hidden">
        <div className="flex items-center justify-between mb-3">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <img src={icon} alt="Logo" height={190} width={190} />
          </Link>

          {/* Language Toggle */}
          <div
            onClick={() => changeLanguage(i18n.language === "en" ? "ar" : "en")}
            className="flex items-center cursor-pointer"
          >
            <span className="text-lg font-medium ml-2">{i18n.language === "ar" ? "EN" : "AR"}</span>
            <img src={Globe} alt="Language Toggle" height={20} width={20} className="ml-2" />
          </div>
        </div>

        {/* Mobile Navigation Links */}
        <nav className="flex flex-row flex-wrap justify-between mt-4">
        <div className="group relative hover:text-gray-900">
  <span className="font-semibold">{t("Our Store")}</span>
  <div
  className="absolute hidden group-hover:flex flex-col bg-white shadow-lg rounded-md w-48 pt-2 z-50"
>
  <a
    href="https://humd.ae/"
    className="flex items-center px-4 py-2 "
    target="_blank"
    rel="noreferrer"
  >
    <img src={flag2} alt="UAE Store" className="w-6 h-6 mx-2" />
    <span>{t("UAE Store")}</span>
  </a>
  <a
     href="https://www.humd.me/"
    className="flex items-center px-4 py-2 "
    target="_blank"
    rel="noreferrer"
  >
    <img src={flagSaudi} alt="Saudi Store" className="w-6 h-6 mx-2" />
    <span>{t("Saudi Store")}</span>
  </a>
</div>

</div>

          <span className="hover:text-gray-900">
            <Link to="/enterprise">{t("Enterprise")}</Link>
          </span>
          <span className="hover:text-gray-900">
            <Link to="/reseller">{t("Resellers")}</Link>
          </span>
          <span className="hover:text-gray-900">
            <Link to="/vendors">{t("Vendors")}</Link>
          </span>
          <span className="ml-2 hover:text-gray-900">
            <Link to="/contactus">{t("Contact")}</Link>
          </span>
        </nav>
      </div>

      {/* Desktop Header */}
      <div className="hidden md:flex flex-wrap p-5 items-center">
        <Link
          to="/"
          className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0 ml-3"
        >
          <img src={icon} alt="Logo" height={190} width={190} />
        </Link>
        <div className="md:ml-auto md:mr-auto flex flex-wrap lg:items-center text-lg lg:justify-center cursor-pointer">
        <div className="relative group mr-5 hover:text-gray-900">
  <span className="font-semibold">{t("Our Store")}</span>
  <div
  className={`absolute hidden group-hover:flex flex-col bg-white shadow-lg py-4 rounded-md ${
    i18n.language === "ar" ? "pr-12 pl-4 rtl" : "pr-24 pl-4 ltr"
  }`}
>
  <a
    href="https://humd.ae/"
    className={`flex items-center py-2 ${
      i18n.language === "ar" ? "flex-row-reverse" : ""
    }`}
    target="_blank"
    rel="noreferrer"
  >
    <img src={flag2} alt="UAE Store" className="w-8 h-6 mx-2" />
    <span className="whitespace-nowrap">{t("UAE Store")}</span>
  </a>
  <a
    href="https://www.humd.me/"
    className={`flex items-center py-2 ${
      i18n.language === "ar" ? "flex-row-reverse" : ""
    }`}
    target="_blank"
    rel="noreferrer"
  >
    <img src={flagSaudi} alt="Saudi Store" className="w-8 h-8 mx-2" />
    <span className="whitespace-nowrap">{t("Saudi Store")}</span>
  </a>
</div>

</div>

          <span className="mr-5 hover:text-gray-900">
            <Link to="/enterprise">{t("Enterprise")}</Link>
          </span>
          <span className="mr-5 hover:text-gray-900">
            <Link to="/reseller">{t("Resellers")}</Link>
          </span>
          <span className="mr-5 hover:text-gray-900">
            <Link to="/vendors">{t("Vendors")}</Link>
          </span>
          <span className="mr-5 hover:text-gray-900">
            <Link to="/contactus">{t("Contact")}</Link>
          </span>
        </div>
        <div className="dropdown">
          <div
            onClick={() => changeLanguage(i18n.language === "en" ? "ar" : "en")}
            className="flex items-center cursor-pointer lg:mr-0 my-2"
          >
            <img
              src={Globe}
              alt="Language Toggle"
              height={20}
              width={20}
              style={{ borderRadius: "50%", marginLeft: "5px" }}
            />
            &nbsp; {i18n.language === "en" ? "AR" : "EN"}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
