"use client";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ContactFormData = () => {
  const { t, i18n } = useTranslation("contactForm");

  useEffect(() => {
    // Dynamically load the HubSpot form script
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.type = "text/javascript";
    script.charset = "utf-8";

    script.onload = () => {
      // Dynamically set the form based on the current language
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const hubspot = (window).hbspt; // Type assertion for HubSpot
      if (hubspot) {
        hubspot.forms.create({
          portalId: "48676665", // Your HubSpot portal ID
          formId:
            i18n.language === "ar"
              ? "7e19d481-ffa7-4d9c-85a2-841717090345" // Arabic Form ID
              : "92c8d930-5a81-4827-9876-00aac4dfe034", // English Form ID
          target: "#hubspot-form", // Target div for the form
          onFormSubmit: () => {
            // Redirect to the homepage after 3 seconds
            setTimeout(() => {
              window.location.href = "/";
            }, 5000);
          },
        });
      } else {
        console.error("HubSpot forms library failed to load.");
      }
    };

    script.onerror = () => {
      console.error("Failed to load HubSpot script.");
    };

    document.body.appendChild(script);

    return () => {
      // Cleanup the script if the component is unmounted
      document.body.removeChild(script);
    };
  }, [i18n.language]);

  return (
    <div className="px-5 lg:py-12 py-8 mx-auto">
      <div className="flex flex-col text-center w-full ">
        <h1 className="lg:text-[45px] text-[25px] font-bold lg:w-2/3 w-full lg:px-12 mx-auto block">
          {t("Be part of our exciting")} <span className="text-green-600">{t("Saudi Arabia")}</span> {t("launch")}!
        </h1>
      </div>
      <div>
        <p className="lg:px-8 px-3 text-center w-full lg:w-2/3 mx-auto block py-4">
          {t("Sign up now with your email and phone number to claim your")} <span className="text-green-600 font-bold">{t("free 1-year")}</span> {t("subscription to a premium digital business card. Connect and grow effortlessly - don’t miss out!")}
        </p>
      </div>

      {/* HubSpot Form Placeholder */}
      <div id="hubspot-form" className="lg:w-2/3 md:w-2/3 mx-auto lg:px-8"></div>
    </div>
  );
};

export default ContactFormData;
